














import { Vue, Component, Prop} from 'vue-property-decorator';

import pick from 'lodash/pick';
import Qs from 'qs';
import LoadingHandler from '@/utils/loading-handler';
import AuthRepository from "@/repositories/auth-repository";
import AuthContainer from "@/views/common/auth/AuthContainer.vue";
import LoginForm from "@/views/common/auth/components/LoginForm.vue";
import UserEntity from "@/entities/user-entity";
import {AuthUser} from "@/libs/auth";

@Component({
  components: {LoginForm, AuthContainer}
})
export default class extends Vue {
  @Prop({required: false}) private email?:string;

  private showMfa: boolean = false;

  private get urlParams() {
    return Qs.parse(location.search.substring(1)) as {showWarning?:boolean, continueURL?:string};
  }
  private get continueUrl() {
    const useContinueUrl = this.urlParams.continueURL && this.urlParams.continueURL.indexOf('/auth/login') === -1;
    return useContinueUrl ? this.urlParams.continueURL! : {name: 'logged-in', params: {companyId: this.$auth.user.company.id as any}};
  }

  private created() {
    if(this.urlParams.showWarning) {
      this.$message({type: 'warning', message: this.$t('ログインが必要です')});
    }
  }

  private async login(user: AuthUser) {
    this.$auth.login(user);

    this.$router.push(this.continueUrl).then(() => {
      this.$message({ type: 'success', message: this.$t('ログインしました'), showClose: true });
    });
  }
}
